// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
global.$ = $
global.jQuery = $

require("jquery")
require("packs/select2")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import "bootstrap"
require("chartkick")
require("chart.js")
require("packs/users")
require("packs/jquery.creditCardValidator")
import "@fortawesome/fontawesome-free/js/all";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
$(window).on('load', function() {
  var count = $('#profile_request_modal').attr('data-pending-count');
  if ( count > 0){
    $('#profile_request_modal').modal('show');
  }
});


$(document).on('ajaxComplete turbolinks:load', function() {

  $('input[type=text]').on('keypress', function (event) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  });
  $('input[type=text]').on('paste', function (event) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var key = event.originalEvent.clipboardData.getData("text");
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  });
  $('input[type=email]').on('keypress', function (event) {
    var regex = new RegExp("^[a-zA-Z0-9.@_-]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  });
 });

var whitelistedURLs = ["business.reddoorz.com", "business.reddoorz.in","localhost:3000"];
$(document).on('turbolinks:before-visit', function(event) {
    var url = event.originalEvent.data.url.split("//")[1].split("/")[0];
    if (!whitelistedURLs.includes(url)) {
       event.preventDefault();
    }
});

$(document).on('ajaxComplete turbolinks:load', function() {
  $("#allotted_budget_modal").modal('show');
  $(".close").on('click', function (event) {
    $("#allotted_budget_modal").modal('hide');
  });
});