import $ from 'jquery'
import 'select2'

$(document).on('ajaxComplete turbolinks:load', function() {
  $('#company_country_id').select2();

  $('#company_city_id').select2();
  $('#company_client_type_id').select2()

  $('#company_country_id').change(function() {
    var id = $(this).val();
    $("#company_country").val($("#company_country_id option:selected").text());
    $.ajax({
      url: "/en/get_cities",
      type: "GET",
      data: {country_id: id},
      success: function (data) {
        var output = [];
        $.each(data, function(key, value)
        {
          output.push('<option value="'+ value[0] +'">'+ value[1] +'</option>');
        });
        $('#company_city_id').html(output.join(''));
      },
      error: function (textStatus, errorThrown) {
        // Success = false;//doesnt goes here
      }
    })
  });

  $('#company_city_id').change(function() {
    $("#company_city").val($("#company_city_id option:selected").text());
  });
})
